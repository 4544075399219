import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import CatalogForm from './CatalogForm'
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  SafeAreaView,
} from 'react-native-web'
import CatalogCard from './CatalogCard'

import colors, { solveColors } from './../../components/colors'
import { FormControlLabel, Switch } from '@material-ui/core'
import CourselistPage from '../Courselist/CourselistPage'
import { isMobile, imagePlaceholder } from './../../../config'
import { withCookies } from 'react-cookie'

class CatalogPage extends Component {
  constructor(props) {
    super(props)
    if (props.cookies.get('token')) {
      props.changeTitles('Catalog')
    }
    this.state = {
      subject: null,
      grade: null,
      session: null,
      location: null,
      loggedIn: props.cookies.get('token') !== null,
      loading: false,
      goToResult: false,
      data: null,
      showCourselist: props.courselist,
    }
  }

  componentDidMount() {
    if (window.location.search) {
      this.setState({ goToResult: true })
    }
  }

  selectSubject = subject => {
    if (this.state.subject === subject) {
      this.setState({ subject: null })
    } else {
      this.setState({ session: null, subject }, () =>
        this.props.history.push(`/catalog?subject=${subject}`)
      )
    }
  }

  selectGrade = grade => {
    if (this.state.grade === grade) {
      this.setState({ grade: null })
    } else {
      this.setState({ grade }, () =>
        this.props.history.push(
          `/catalog?grade=${grade.startGrade}-${grade.endGrade}`
        )
      )
    }
  }

  selectSession = session => {
    if (this.state.session === session) {
      this.setState({ session: null })
    } else {
      this.setState({ session, subject: null }, () =>
        this.props.history.push(`/catalog?session=${JSON.stringify(session)}`)
      )
      //fetching data right away
    }
  }

  selectLocation = location => {
    if (this.state.location === location) {
      this.setState({ location: null })
    } else {
      this.setState({ location }, () =>
        this.props.history.push(`/catalog?location=${location}`)
      )
    }
  }

  render() {
    const { goToResult, showCourselist } = this.state
    if (goToResult) {
      return <CatalogForm />
    }
    const coursesListLabel = !showCourselist
      ? 'Display Courses in a Table'
      : 'Display Courses in Categories'
    return (
      <ScrollView
        indicatorStyle={'black'}
        style={{ backgroundColor: 'white' }}
        contentContainerStyle={{ alignItems: 'center' }}
      >
        <SafeAreaView>
          <View style={[styles.notifyContainer, styles.notifyBody]}>
            <Text style={styles.notifyHeader}>Welcome to Ardent Academy!</Text>
            <FormControlLabel
              control={
                <Switch
                  color={'primary'}
                  checked={showCourselist}
                  onChange={e =>
                    this.setState({ showCourselist: e.target.checked })
                  }
                  name="courselistToggle"
                />
              }
              label={
                <Text style={{ color: solveColors.blue3 }}>
                  {coursesListLabel}
                </Text>
              }
            />
          </View>
          <br />
          {showCourselist && (
            <View style={{ overflow: 'hidden', padding: '2vw' }}>
              <CourselistPage />
            </View>
          )}
          {!showCourselist && (
            <div>
              <View>
                {/* <Text style={styles.header}>Quarters</Text> */}
                <View style={[styles.categoryContainer, styles.notifyBody]}>
                  {sessions.map((session, index) => (
                    <CatalogCard
                      image={session.img}
                      key={index}
                      selected={this.state.session === session} //current selected equals to its tag
                      tag={session}
                      title={session.name}
                      field1={session.duration}
                      field3={session.location}
                      field2={`Promo Deadline: ${session.earlybird}`}
                      onPress={() => {
                        this.selectSession(session)
                      }}
                    />
                  ))}
                  <View>
                    <br />
                    <View
                      style={{
                        flexDirection: 'row',
                        marginBottom: '1px',
                        width: '80vw',
                      }}
                    >
                      {/* <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          Search for a class:{' '}
                        </span>
                        Click on a program below.
                      </Text> */}
                    </View>
                    {/* <View style={{ flexDirection: 'row', width: '80vw' }}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          All grades are rising grades in Fall 2022 for summer
                          classes.
                        </span>
                      </Text>
                    </View> */}
                    <View style={{ flexDirection: 'row', width: '80vw' }}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          New Student Discount:{' '}
                        </span>
                        Save $100.
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '80vw' }}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          Early Bird Promotion:{' '}
                        </span>
                        Save $50 per class.
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '80vw' }}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          Learn More Promotion:{' '}
                        </span>
                        Save $100 depending class type.
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '80vw' }}>
                      <Text>
                        <span style={{ fontWeight: 'bold' }}>
                          Registration Hotline:{' '}
                        </span>
                        Call us at (949) 861-2211, 10:00AM - 6:00PM, MON - SUN.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.header}>Programs</Text>
                <View style={[styles.categoryContainer, styles.notifyBody]}>
                  {subjects.map((subject, index) => (
                    <CatalogCard
                      image={subject.img || imagePlaceholder}
                      selected={this.state.subject === subject.value} //current selected equals to its tag
                      key={index}
                      tag={subject.name}
                      title={subject.name}
                      onPress={
                        subject.value === 'test prep'
                          ? () => {}
                          : () => {
                              this.selectSubject(subject.value)
                            }
                      }
                    />
                  ))}
                </View>
              </View>
              <View>
                <Text style={styles.header}>Grades</Text>
                <View style={[styles.categoryContainer, styles.notifyBody]}>
                  {/* style={[
                styles.categoryContainer,
                loggedIn ? styles.loggedInBody : styles.notifyBody,
              ]}
            > */}
                  {gradeRanges.map((grade, index) => (
                    <CatalogCard
                      image={grade.img}
                      key={index}
                      selected={
                        grade.startGrade <= this.state.grade &&
                        grade.endGrade >= this.state.grade
                      } //current selected equals to its tag
                      tag={grade}
                      // title={`Grade ${
                      //   grade.startGrade === 1 ? 'K' : grade.startGrade
                      // }-${grade.endGrade}`}
                      // field1={`${QUARTER} ${YEAR}`}
                      onPress={() => this.selectGrade(grade)}
                    />
                  ))}
                  <View style={{ flexDirection: 'row', width: '80vw' }}>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>
                        Early Bird Promotion:{' '}
                      </span>
                      Save $50.
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', width: '80vw' }}>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>
                        Learn More Promotion:{' '}
                      </span>
                      Save $50 or $100 depending class type.
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', width: '80vw' }}>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>
                        Registration Hotline:{' '}
                      </span>
                      Call us at (949) 861-2211, 10:00AM - 6:00PM, MON - SUN.
                    </Text>
                  </View>
                </View>
              </View>
              {/* <View>
          <Text style={styles.header}>Location</Text>
          <View style={styles.categoryContainer}>
            {locations.map(location => (
              <CatalogCard
                image={imagePlaceholder}
                selected={this.state.location === location} //current selected equals to its tag
                tag={location}
                description={location}
                onPress={() => this.selectLocation(location)}
              />
            ))}
          </View>
        </View> */}

              {/* <TouchableOpacity
                disabled={loading}
                onPress={() => this.fetchData(this.props.client)}
                style={styles.search}
              >
                <Text style={styles.searchText}>
                  {loading ? 'Loading..' : 'Search'}
                </Text>
              </TouchableOpacity> */}
            </div>
          )}
        </SafeAreaView>
      </ScrollView>
    )
  }
}

export default withRouter(withCookies(CatalogPage))

const styles = StyleSheet.create({
  categoryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    paddingLeft: '2vw',
  },
  header: {
    color: '#2D97DF',
    padding: '1vh',
    marginLeft: '2vw',
    fontWeight: 'bold',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    fontSize: '17px',
  },
  search: {
    backgroundColor: colors.turquoise,
    paddingTop: '2vh',
    paddingBottom: '2vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  catalog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '25px',
    backgroundColor: colors.turquoise,
    color: 'white',

    padding: 10,
  },
  notifyContainer: { marginLeft: '2.5vw ' },
  notifyHeader: {
    fontSize: '21px',
    color: colors.blue5,
    marginTop: '10px',
  },
  notifyBody: { width: '90vw' },
  loggedInBody: { width: 'calc(90vw - 110px)' },
})

const subjects = [
  {
    name: 'Grades 8 - 12',
    value: 'AP',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-apcourse.png',
  },
  // {
  //   name: 'Grades 6 - 12',
  //   value: 'Research',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-research.jpg',
  // },
  {
    name: 'Grades K - 12',
    value: 'Olympiad',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-matholympiad.jpg',
  },
  {
    name: 'Grades 3 - 12',
    value: 'Accelerated',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-math.jpg',
  },

  // {
  //   name: 'Grades 3 - 10',
  //   value: 'English',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-english.jpeg',
  // },
  // {
  //   name: 'Grades 4 - 8',
  //   value: 'Communication',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-speech.jpg',
  // },
  {
    name: 'Grades 3 - 8',
    value: 'Science',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-science.jpg',
  },

  {
    name: 'Grades 7 - 12',
    value: 'Biology',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-biology.jpg',
  },
  {
    name: 'Grades 8 - 12',
    value: 'Chemistry',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-chemistry.jpg',
  },
  {
    name: 'Grades 7 - 12',
    value: 'Physics',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-physics.jpg',
  },
  {
    name: 'Grades 2 - 11',
    value: 'Programming',
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-coding.jpeg',
  },

  // {
  //   name: 'Grades 8 - 12',
  //   value: 'SAT Prep',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-testprep.jpeg',
  // },

  // {
  //   name: 'Robotics',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-science.jpg',
  // },

  // {
  //   name: 'Artificial Intelligence',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-science.jpg',
  // },
  // {
  //   name: 'Python',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-coding.jpeg',
  // },

  // {
  //   name: 'Science and Engineering',
  //   img:
  //     'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/subject-science.jpg',
  // },
]

const gradeRanges = [
  {
    startGrade: 1,
    endGrade: 2,
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/cover-gradek-2.jpg',
  },
  {
    startGrade: 3,
    endGrade: 5,
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/cover-grade3-5.jpg',
  },
  {
    startGrade: 6,
    endGrade: 8,
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/cover-grade6-8.jpg',
  },
  {
    startGrade: 9,
    endGrade: 12,
    img:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/cover-grade9-12.jpg',
  },
]

const sessionImgs = {
  Spring:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-spring.png',
  PreSummer:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-summer.png',
  Summer:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-summer.png',
  Fall:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-fall.png',
  Winter:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-winter.png',
  OnDemand:
    'https://ardent-shared-media.s3.us-west-2.amazonaws.com/webui/quarter-selfpaced.png',
  Tutoring:
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/webui/quarter-tutoring.jpeg',
  Contest:
    'https://ardent-shared-media.s3.us-west-2.amazonaws.com/webui/quarter-contest.png',
}

const sessions = [
  // {
  //   name: 'Spring',
  //   year: '2022',
  //   quarter: 'Spring',
  //   session: '1',
  //   duration: 'Feb. 28, 2022 - May 8, 2022',
  //   location: 'LIVE ONLINE or IN-PERSON',
  //   earlybird: 'February 21',
  //   img: sessionImgs['Spring'],
  // },
  // {
  //   name: 'Winter',
  //   year: '2022',
  //   quarter: 'Winter',
  //   session: '1',
  //   duration: 'Nov. 29, 2021 - Feb. 20, 2022',
  //   location: 'LIVE ONLINE or IN-PERSON',
  //   earlybird: 'November 28',
  //   img: sessionImgs['Winter'],
  // },
  {
    name: '1-on-1 Tutoring',
    year: '2024',
    quarter: 'Tutoring',
    session: '1',
    duration: 'Start at Anytime',
    location: 'LIVE ONLINE or IN-PERSON',
    earlybird: 'N/A',
    img: sessionImgs['Tutoring'],
  },
  {
    name: 'Competition',
    year: '2025',
    quarter: 'Contest',
    session: '1',
    duration: 'TBD',
    location: 'IN-PERSON',
    earlybird: 'N/A',
    img: sessionImgs['Contest'],
  },
  // {
  //   name: 'Self-Paced Learning',
  //   year: '2022',
  //   quarter: 'OnDemand',
  //   session: '1',
  //   duration: 'Start at Anytime',
  //   location: 'SELF-PACED, ONLINE',
  //   earlybird: 'TBD',
  //   img: sessionImgs['OnDemand'],
  // },
  // {
  //   name: 'Contest Registrations',
  //   year: '2022',
  //   quarter: 'Contest',
  //   session: '1',
  //   duration: 'November 1 - May 31, 2022',
  //   location: 'TBD',
  //   earlybird: 'Club',
  //   img: sessionImgs['Contest'],
  // },
  // {
  //   name: 'Online',
  //   year: '2024',
  //   quarter: 'Fall',
  //   session: '1',
  //   duration: 'September 9 - November 17, 2024',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'August 26',
  //   img: sessionImgs['Fall'],
  // },
  // {
  //   name: 'In-Person',
  //   year: '2024',
  //   quarter: 'Fall',
  //   session: '2',
  //   duration: 'September 9 - November 17, 2024',
  //   location: 'Northwood',
  //   earlybird: 'August 26',
  //   img: sessionImgs['Fall'],
  // },
  // {
  //   name: 'Online',
  //   year: '2024',
  //   quarter: 'Spring',
  //   session: '1',
  //   duration: 'Mar. 4, 2024 - May 12, 2024',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'February 19',
  //   img: sessionImgs['Spring'],
  // },
  // {
  //   name: 'In-Person',
  //   year: '2024',
  //   quarter: 'Spring',
  //   session: '2',
  //   duration: 'Mar. 4, 2024 - May 12, 2024',
  //   location: '820 Roosevelt, Irvine',
  //   earlybird: 'Febuary 19',
  //   img: sessionImgs['Spring'],
  // },
  {
    name: 'Online',
    year: '2025',
    quarter: 'Winter',
    session: '1',
    duration: 'Dec. 2, 2024 - Feb. 23, 2025',
    location: 'LIVE ONLINE',
    earlybird: 'November 18',
    img: sessionImgs['Winter'],
  },
  {
    name: 'In-Person',
    year: '2025',
    quarter: 'Winter',
    session: '2',
    duration: 'Dec. 2, 2024 - Feb. 23, 2025',
    location: 'Northwood',
    earlybird: 'November 18',
    img: sessionImgs['Winter'],
  },
  {
    name: 'Winter Bootcamp',
    year: '2024',
    quarter: 'Winter',
    session: '1',
    duration: 'December 26 - 30, 2024',
    location: 'ONLINE',
    earlybird: 'December 9',
    img: sessionImgs['Winter'],
  },
  // {
  //   name: 'Winter Bootcamp II',
  //   year: '2024',
  //   quarter: 'Winter',
  //   session: '3',
  //   duration: 'January 2 - 6, 2024',
  //   location: 'ONLINE',
  //   earlybird: 'December 21',
  //   img: sessionImgs['Winter'],
  // },
  // {
  //   name: 'Pre-summer',
  //   year: '2022',
  //   quarter: 'PreSummer',
  //   session: '1',
  //   duration: 'May 9 - June 5, 2022',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'April 25, 2022',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 1',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '1',
  //   duration: 'June 10 - June 21, 2024',
  //   location: 'LIVE ONLINE or IN-PERSON',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 2',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '2',
  //   duration: 'June 24 - July 05, 2024',
  //   location: 'LIVE ONLINE or IN-PERSON',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 3',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '3',
  //   duration: 'July 15 - August 16, 2024',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 4',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '4',
  //   duration: 'July 22 - August 2, 2024',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 5',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '5',
  //   duration: 'August 5 - Auguest 16, 2024',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 6',
  //   year: '2024',
  //   quarter: 'Summer',
  //   session: '6',
  //   duration: 'July 13 - August 10, 2024',
  //   location: 'IN-PERSON',
  //   earlybird: 'May 20, 2024',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 7',
  //   year: '2022',
  //   quarter: 'Summer',
  //   session: '7',
  //   duration: 'June 11 - August 13, 2022',
  //   location: 'IN-PERSON at NORTHWOOD',
  //   earlybird: 'May 16, 2022',
  //   img: sessionImgs['Summer'],
  // },
  // {
  //   name: 'Session 8',
  //   year: '2022',
  //   quarter: 'Summer',
  //   session: '8',
  //   duration: 'Auguest 8 - August 19, 2022',
  //   location: 'LIVE ONLINE',
  //   earlybird: 'June 22, 2022',
  //   img: sessionImgs['Summer'],
  // },
]

// const years = [2020, 2021, 2022]

// const locations = ['Online', 'UTC', 'Northwood', 'Woodbridge']
